import React, { useState } from 'react';
import { Box, TextField, Grid } from '@mui/material';
import Button from "../components/Button";
import TextColor from './TextColor';

const ContactForm = () => {
  // State to hold form inputs
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email_id: '',
    phone: '',
    address: '',
    message: ''
  });

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedData = {
        first_name: formData.firstName,      
        last_name: formData.lastName,        
        email_id: formData.email,            
        phone: formData.phone,               
        address: formData.address,           
        message: formData.message           
    };

    // API call using fetch
    fetch('http://192.168.0.68:8000/api/v1/contacts/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedData),  
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('Submitted successfully:', data);
      // Show success alert
      alert('Submitted successfully!');
    })
    .catch(error => {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    });
};

  return (
    <Box
      component="form"
      sx={{
        width: { xs: "100%", md: "31.3vw" },
        '@media(max-width:600px)': {
          gap: 2,
          alignItems: "center"
        },
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        justifyContent: "center",
      }}
      onSubmit={handleSubmit}      
    >
      <Box sx={{}}>
        <TextColor
          text="Get Your Health product today!"
          highlightWords={['product', 'today', '!']}
          highlightColor="#3f51b5"
          defaultColor="#000000"
          styleType="sub11"
        />
      </Box>

      {/* Form Fields */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            variant="standard"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            variant="standard"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="E-mail"
            variant="standard"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone Number"
            variant="standard"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address"
            variant="standard"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Message"
            variant="standard"
            multiline
            rows={3}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Box textAlign="center">
        <Button text="Submit" />
      </Box>
    </Box>
  );
};

export default ContactForm;
