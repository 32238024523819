import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import ImageComponent from "../components/Images";
import avatar from "../assets/images/avatar.png";
import card_image from "../assets/images/card_image.png";
import background from "../assets/images/background.png";
import BackgroundImage from "../components/backgroundImage";
import AlertChip from "../components/AlertChip";
import svasthlogo from "../assets/images/Svasthlogo.png";
import alegionlogo from "../assets/images/alegionlogo.png";
import clock from "../assets/images/clock.png";
import Card1 from "../components/Card1";
import Card2 from "../components/Card2";
import card2 from "../assets/images/card2.png";
import cd3 from "../assets/images/cd3.png";
import cd2 from "../assets/images/cd2.png";
import cd4 from "../assets/images/cd4.png";
import cd5 from "../assets/images/cd5.png";
import cad3 from "../assets/images/cad3.png";
import cad4 from "../assets/images/cad4.png";
import img1 from "../assets/images/img1.png";
import img2 from "../assets/images/img2.png";
import img3 from "../assets/images/img3.png";
import img4 from "../assets/images/img4.png";
import accident from "../assets/images/accident.png";
import cad1 from "../assets/images/cad1.png";

import FirstPageCard from "../components/FirstPageCard";
import Button from "../components/Button";
import BlackCard from "../components/Blackcard";
import Heart from "../assets/images/heart.jpg";
import Heartimg from "../assets/images/heartimg.jpg";
import illness from "../assets/images/illness.png";
import Card3 from "../components/Card3";
import sqcd1 from "../assets/images/sqcd1.png";
import sqcd6 from "../assets/images/sqcd6.png";
import sqcd2 from "../assets/images/sqcd2.png";
import sqcd3 from "../assets/images/sqcd3.png";
import sqcd4 from "../assets/images/sqcd4.png";
import sqcd5 from "../assets/images/sqcd5.png";
import Footer from "../components/Footer";
import TextColor from "../components/TextColor";
import Layout from "../components/Layout";
import { FontStyle } from "../assets/styles";
import background2 from "../assets/images/background2.png";
import NavBar from "../components/NavBar";
import Header from "../components/Navbarmobile";

const MyApp = () => {
  const navigate = useNavigate();

  const aboutClick = () => {
    navigate("/about");
  };
  const contactClick = () => {
    navigate("/contact");
  };

  return (
    <Stack display={"flex"}>
      <Box>
        {/* <BackgroundImage image={background} style={{width:"818.33px", height:"878.81px",'@media (max-width: 600px)': {}, }} />  */}
        <Layout>
          <NavBar logo={alegionlogo} />
          <Header logoImage={alegionlogo} />
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ alignItems: "center", gap: 6, position: "relative" }}
          >
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"space-between"}
              sx={{ alignItems: "center" }}
            >
              <Stack
                width={{ xs: "100%", sm: "55%" }}
                sx={{
                  gap: 7,
                  "@media (max-width: 600px)": {
                    gap: 2,
                    alignItems: "center",
                  },
                }}
              >
                <AlertChip
                  textStyle={FontStyle.sub51}
                  imageSrc={clock}
                  label={"Every Second Counts!"}
                />
                <Stack
                  sx={{
                    "@media (max-width: 600px)": {
                      alignItems: "center",
                    },
                  }}
                >
                  <Box
                    sx={{
                      "@media (max-width: 600px)": {
                        textAlign: "center",
                        width: "348px",
                      },
                    }}
                  >
                    <TextColor
                      text="Panicking over the Uncertainty of ⚠️ Health Management?"
                      highlightWords={["Uncertainty"]}
                      highlightColor="#008000"
                      defaultColor="#000000"
                      styleType="h31"
                    />
                  </Box>
                  <TextColor
                    text="Ensures Timely Care With AI-Driven Precision, Easing Anxiety "
                    highlightWords={["AI-Driven", "Precision"]}
                    highlightColor="#FF0000"
                    defaultColor="#000000"
                    styleType="body11"
                  />
                  <TextColor
                    text=" During Critical Moments Through Rapid Response."
                    highlightWords={[]}
                    highlightColor=""
                    defaultColor="#000000"
                    styleType="body11"
                  />
                </Stack>
                <Box>
                  <Button text="Learn More" onClick={aboutClick} />
                </Box>
                <Stack
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  sx={{
                    "@media (max-width: 600px)": {
                      paddingTop: "16.11px",
                      alignItems: "center",
                    },
                  }}
                >
                  <TextColor
                    text="Aarogyameva Artha"
                    highlightWords={[]}
                    highlightColor=""
                    defaultColor="#303A99"
                    styleType="sub2"
                  />
                  <TextColor
                    text="Health is the only Wealth!"
                    highlightWords={[]}
                    highlightColor=""
                    defaultColor="#000000"
                    styleType="sub52"
                  />
                </Stack>
              </Stack>

              <Stack
                width="42%"
                minWidth="360px"
                justifyContent="center"
                alignItems={"center"}
              >
                <ImageComponent
                  style={{ width: "100%", height: "100%" }}
                  Image={avatar}
                />
              </Stack>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                alignItems: "center",
                gap: 3,
                position: "relative",
                "@media (max-width: 600px)": {
                  gap: 1,
                },
              }}
            >
              <TextColor
                text=" Innovative Care for Every Step of Your Health Journey."
                highlightWords={["Innovative", "Care"]}
                highlightColor="#303A99"
                defaultColor="#000000"
                styleType="h42"
              />
              <Stack
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  textAlign: "center",
                  width: "1303px",
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <TextColor
                  text="Svasth’s innovative approach covers every aspect of your health, from outpatient and inpatient care to holistic wellness programs and our game-changing emergency response system - QRify."
                  highlightWords={[]}
                  highlightColor="#000000"
                  defaultColor="#000000"
                  styleType="body32"
                />
              </Stack>
              <Box
                display="flex"
                flexDirection="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: 4,
                }}
              >
                <Card1
                  title="OUTPATIENT CARE"
                  body="Immediate access to top-notch healthcare without hospital stays."
                  description="Reduces hospital admission costs and resource strain, allowing healthcare providers to deliver efficient care while optimising operations."
                  imageSrc={card_image}
                  linkText="Learn More"
                />
                <Card1
                  title="IMPATIENT CARE"
                  body="Comprehensive services with state-of-the-art technology and expert medical care."
                  description="Enhances patient outcomes and satisfaction, leading to higher efficiency, which ultimately lowers long-term costs for healthcare providers."
                  imageSrc={cd2}
                  linkText="Learn More"
                />
                <Card1
                  title=" WELLNESS PROGRAMS"
                  body="Tailored plans integrating health monitoring and preventive care with risk management."
                  description=" Monitors and enhances your well-being, alerting you to any needed medical intervention, so you stay at your best."
                  imageSrc={cd3}
                  linkText="Learn More"
                />
                <Card1
                  title="QRIFY EMERGENCY RESPONSE SYSTEM"
                  body="Tech-driven system ensuring immediate response in emergencies via use of a QR code"
                  description=" Bridges the gap between incident & intervention, ensuring the quickest, most effective care in Emergencies where every second counts."
                  imageSrc={cd4}
                  linkText="Learn More"
                />
                <Card1
                  title="INSURANCE COLLABORATION"
                  body="Strategic partnerships with insurance companies to optimise portfolio management."
                  description="Aligns financial coverage with quality care, making healthcare more accessible and affordable."
                  imageSrc={cd5}
                  linkText="Learn More"
                />
                <Box
                  component="img"
                  src={img1}
                  alt="Description"
                  sx={{
                    position: "absolute",
                    width: "190px",
                    height: "260.82px",
                    bottom: "0",
                    left: "-5.4%",
                    "@media (max-width: 600px)": {
                      display: "none", // Hide on mobile
                    },
                  }}
                />
                <Box
                  component="img"
                  src={img2}
                  alt="Description"
                  sx={{
                    position: "absolute",
                    width: "350px",
                    height: "350.82px",
                    bottom: "0",
                    right: "-5.4%",
                    "@media (max-width: 600px)": {
                      display: "none",
                    },
                  }}
                />
              </Box>
            </Box>
            <Stack
              gap={10}
              sx={{
                "@media (max-width: 600px)": {
                  gap: 1,
                },
              }}
            >
              <Stack
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  alignItems: "center",
                  gap: 3,
                  "@media (max-width: 600px)": {
                    gap: 1,
                    textAlign: "center",
                  },
                }}
              >
                <TextColor
                  text=" Redefining Healthcare for a Healthier Tomorrow"
                  highlightWords={["Healthier", "Tomorrow"]}
                  highlightColor="#303A99"
                  defaultColor="#000000"
                  styleType="h42"
                />
                <TextColor
                  text="Accessible, personalised care powered by technology to meet the challenges of today’s world."
                  highlightWords={[]}
                  highlightColor="#000000"
                  defaultColor="#000000"
                  styleType="body23"
                />
              </Stack>
              <Box
                display="flex"
                flexDirection="row"
                sx={{
                  gap: 7,
                  flexWrap: "wrap",
                  "@media (max-width: 600px)": { gap: 1 },
                }}
              >
                <Card2
                  imageSrc={cad1}
                  title="Rising Healthcare Costs"
                  description="With medical expenses skyrocketing, Svasth offers affordable, tech-driven solutions that ensure quality care without the financial burden."
                />
                <Card2
                  imageSrc={card2}
                  title="Chronic Disease Management"
                  description="60% of people over 45 live with chronic illness. Our tailored plans and real-time monitoring help manage these conditions effectively."
                />
                <Card2
                  imageSrc={cad3}
                  title="Aging Population"
                  description="As people live longer, the need for comprehensive, continuous care is growing. Svasth’s integrated services cater to the needs of anyone."
                />
                <Card2
                  imageSrc={cad4}
                  title="Technology Integration"
                  description="With healthcare increasingly relying on technology, Svasth is at the forefront, using real-time data and AI to revolutionize health management."
                />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                paddingBottom={5}
                sx={{
                  alignItems: "center",
                  gap: 3,
                  "@media (max-width: 600px)": {
                    gap: 1,
                    textAlign: "center",
                  },
                }}
              >
                <TextColor
                  text=" Discover the solution that empowers you to confidently overcome challenges for a healthier future."
                  highlightWords={[]}
                  highlightColor="#000000"
                  defaultColor="#000000"
                  styleType="body11"
                />
                <Button text=" Contact Us" onClick={contactClick} />
              </Box>
            </Stack>
          </Box>
        </Layout>
      </Box>
      <Layout backgroundColor={"linear-gradient(to right, #1e1e1e, #0a192f)"}>
        <BlackCard
          imageLeft={false}
          image={accident}
          title="Quick Response to Medical Emergencies"
          subtitle="Lifesavr’s Swift Aid"
          description="In a serious car accident, a driver is left vulnerable.Yet,immediate care is delivered, thanks to the Lifesavr on the scene. Their quick, action using our solution ensures the driver receives the critical aid needed in those vital moments."
          buttonText="Know More"
          buttontextColor={"#303A99"}
          buttonBackgroundColor="#ffffff"
          onClick={aboutClick}
        />
      </Layout>
      <Box>
        <Layout>
          <BlackCard
            imageLeft={true}
            background={"#ffffff"}
            textcolor={"#000000"}
            image={illness}
            buttonBackgroundColor={"#303A99"}
            buttontextColor={"#ffffff"}
            title="Emergency Response for Critical Illness"
            subtitle="Urgent Care Delivered"
            description="In a critical moment, a colleague’s quick intervention ensures the office worker with heart disease receives urgent care. Our solution empowers Lifesavrs with the tools for swift action in Emergencies."
            buttonText="Know More"
            onClick={aboutClick}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <TextColor
                text=" AI Meets Health Management"
                highlightWords={["Management"]}
                highlightColor="#303A99"
                defaultColor="#000000"
                styleType="h42"
              />
              <Box
                display="flex"
                sx={{
                  alignContent: "center",
                  textAlign: "center",
                  width: "1488px",
                  "@media (max-width: 600px)": {
                    gap: 1,
                    width: "328px",
                    textAlign: "center",
                  },
                }}
              >
                <TextColor
                  text="Combining cutting-edge technology with health and wellness, Svasth offers peace of mind and safety in every health condition. Embrace a safer, more balanced future with our health management protocol system."
                  highlightWords={[]}
                  highlightColor="#000000"
                  defaultColor="#000000"
                  styleType="body23"
                />
              </Box>
              <Box
                component="img"
                src={img3}
                alt="Description"
                sx={{
                  width: "300px",
                  paddingLeft: "30px",
                  position: "absolute",

                  left: "0.08%",
                  right: "0.03%",
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 3,
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Card3
                title="AI-Driven Healthcare Support"
                description="Get swift, accurate help when it matters most, easing your worries and enhancing vital, critical care."
                imageSrc={sqcd1}
              />
              <Card3
                title="Effortless Real-time Coordination"
                description="Navigate Emergencies with ease as we smooth out delays and chaos in health management processes."
                imageSrc={sqcd2}
              />
              <Card3
                title="Instant Alerts & Location"
                description="Stay connected and save time with instant notifications and real-time location sharing, simplifying processes"
                imageSrc={sqcd3}
              />
              <Card3
                title="Up-to-Date Medical Info"
                description="Receive updated medical data for relevant stakeholders, ensuring you get the right care efficiently."
                imageSrc={sqcd4}
              />
              <Card3
                title="Rapid Emergency Response"
                description="Experience quicker Emergency response with real-time updates and efficient coordination, cutting down your wait."
                imageSrc={sqcd5}
              />
              <Card3
                title="Complete Care & Financial Ease"
                description="Enjoy stress-free support and seamless management of medical costs, so you can focus on what matters."
                imageSrc={sqcd6}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            mt={6}
            sx={{
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
          >
            <FirstPageCard
              title="Join us in shaping a healthier, more connected future."
              subtitle="At Svasth, we’re not just keeping up with the changing times we’re leading the way. "
              backgroundImage={Heart}
            />
          </Box>
          <Box
            sx={{
              "@media (max-width: 1920px)": {
                display: { xs: "flex", sm: "none" },
              },
            }}
          >
            <FirstPageCard
              title="Join us in shaping a healthier, more connected future."
              subtitle="At Svasth, we’re not just keeping up with the changing times we’re leading the way. "
              backgroundImage={Heartimg}
            />
          </Box>
        </Layout>
        <Footer></Footer>
      </Box>
    </Stack>

    // <>
    //   <BackgroundImage imageSrc={background} style={{
    //     width: '818.33px',
    //     height: '878.81px'
    //   }} />
    //   <Box display={"flex"} flexDirection={'column'} sx={{ width: "1920px", gap: 6 }}>
    //     <Box>
    //     <Box display={"flex"} flexDirection={'row'} sx={{ height: "1081px" }}>
    //       <Box display={"flex"} flexDirection={"column"} sx={{ width: "50%", height: "100%", justifyContent: "center"}}>
    //         <Box display={"flex"} flexDirection={"column"} sx={{ gap: 6 }}>
    //           <Box display={"flex"} flexDirection={"column"} sx={{ gap: 9 }}>
    //             <Box display={"flex"} flexDirection={"column"} sx={{ gap: 7 }}>
    //               <Box>
    // <AlertChip
    //   textStyle={FontStyle.sub51}
    //   imageSrc={clock}
    //   label={"Every Second Counts!"}
    // />
    //               </Box>
    //               <Box display={"flex"} flexDirection={"column"} sx={{ gap: 0 }}>
    //                 <Box>
    //                   <TextColor
    //                     text="Panicking over the  Uncertainty"
    //                     highlightWords={['Uncertainty']}
    //                     highlightColor="#008000"
    //                     defaultColor="#000000"
    //                     styleType="h31"
    //                   />
    //                 </Box>
    //                 <Box>
    //                   <TextColor
    //                     text=" of ⚠️ Health Management?"
    //                     highlightWords={[]}
    //                     highlightColor="#000000"
    //                     defaultColor="#000000"
    //                     styleType="h31"
    //                   />
    //                 </Box>
    //                 <Box>
    //                   <Box>
    //                     <TextColor
    //                       text=" Ensures Timely Care With AI-Driven Precision, Easing Anxiety"
    //                       highlightWords={['AI-Driven', 'Precision']}
    //                       highlightColor="#FF0000"
    //                       defaultColor="#000000"
    //                       styleType="body11"
    //                     />
    //                   </Box>
    //                   <Box>
    //                     <TextColor
    //                       text=" During Critical Moments Through Rapid Response."
    //                       highlightWords={[]}
    //                       highlightColor=""
    //                       defaultColor="#000000"
    //                       styleType="body11"
    //                     />
    //                   </Box>
    //                 </Box>
    //               </Box>
    //             </Box>
    //             <Box>
    //               <Button text="Learn More" onClick={handleClick} />
    //             </Box>
    //           </Box>
    //         </Box>
    //         <Box >
    //           <TextColor
    //             text=" Aarogyameva Artha "
    //             highlightWords={[]}
    //             highlightColor=""
    //             defaultColor="#303A99"
    //             styleType="sub2"
    //           />
    //         </Box>
    //         <Box>
    //           <TextColor
    //             text=" Health is the only Wealth!"
    //             highlightWords={[]}
    //             highlightColor=""
    //             defaultColor="#000000"
    //             styleType="sub52"
    //           />
    //         </Box>
    //       </Box>
    //       <Box sx={{alignItems:"end",}}>
    //         <ImageComponent
    //           style={{ width: "929.59px", height: "854.81px" }}
    //           Image={avatar}
    //           aiImage={aiImage}
    //         />
    //       </Box>
    //     </Box>

    //     </Box>
    //     <Box flexDirection={"column"} sx={{
    //       width: "1920px", height: "1361px", display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       textAlign: "center",
    //       gap: 3
    //     }}>

    //       <Box display={"flex"} flexDirection={"column"} sx={{ gap: 5 }}>
    //         <Box>
    //           <TextColor
    //             text=" Innovative Care for Every Step of Your Health Journey."
    //             highlightWords={['Innovative','Care']}
    //             highlightColor="#303A99"
    //             defaultColor="#000000"
    //             styleType="h42"
    //           />
    //         </Box>
    //         <Box display={"flex"} flexDirection={"column"} sx={{ gap: 5 }}>
    //           <Box display={"flex"} flexDirection={"column"} sx={{ alignItems: "center" }}>
    //             <TextColor
    //               text="Svasth’s innovative approach covers every aspect of your health, from outpatient and inpatient care to holistic wellness"
    //               highlightWords={[]}
    //               highlightColor="#000000"
    //               defaultColor="#000000"
    //               styleType="body23"
    //             />
    //             <TextColor
    //               text="programs and our game-changing emergency response system - QRify."
    //               highlightWords={[]}
    //               highlightColor="#000000"
    //               defaultColor="#000000"
    //               styleType="body21"
    //             />
    //           </Box>
    //           <Box
    //             display="flex"
    //             flexDirection="row"
    //             justifyContent="space-between"
    //             gap="60px"
    //             sx={{
    //               flexWrap: 'wrap',

    //               isolation: 'isolate'
    //             }}
    //           >
    //             <Card1
    //               title="Outpatient Care"
    //               body="Immediate access to top-notch healthcare without hospital stays."
    //               description="Reduces hospital admission costs and resource strain, allowing healthcare providers to deliver efficient care while optimising operations."
    //               imageSrc={card_image}
    //               linkText="Learn More"
    //             />
    //             <Card1
    //               title="Inpatient Care"
    //               body="Comprehensive services with state-of-the-art technology and expert medical care."
    //               description="Enhances patient outcomes and satisfaction, leading to higher efficiency, which ultimately lowers long-term costs for healthcare providers."
    //               imageSrc={cd2}
    //               linkText="Learn More"
    //             />
    //             <Card1
    //               title="WELLNESS PROGRAMS"
    //               body="Tailored plans integrating health monitoring and preventive care with risk management."
    //               description=" Monitors and enhances your well-being, alerting you to any needed medical intervention, so you stay at your best."
    //               imageSrc={cd3}
    //               linkText="Learn More"
    //             />
    //             <img
    //               src={img1}
    //               alt="Description "
    //               style={{ width: "190px", height: "260.82px", paddingTop: "170px" }}
    //             />
    //             <Card1
    //               title="QRify Emergency Response System"
    //               body="Tech-driven system ensuring immediate response in emergencies via use of a QR code"
    //               description=" Bridges the gap between incident & intervention, ensuring the quickest, most effective care in Emergencies where every second counts."
    //               imageSrc={cd4}
    //               linkText="Learn More"
    //             />
    //             <Card1
    //               title="Insurance Collaboration"
    //               body="Strategic partnerships with insurance companies to optimise portfolio management."
    //               description="Aligns financial coverage with quality care, making healthcare more accessible and affordable."
    //               imageSrc={cd5}
    //               linkText="Learn More"
    //             />
    //             <img
    //               src={img2}
    //               alt="Description "
    //               style={{ width: "350px", height: "350px" }}
    //             />
    //           </Box>
    //         </Box>
    //       </Box>
    //     </Box>

    //     <Box flexDirection={"column"} sx={{ alignItems: "center", width: "1920px",}}>
    //       <Box display={"flex"} flexDirection={"column"} sx={{ alignItems: "center", gap: 4 }}>
    //         <Box>
    //           <TextColor
    //             text=" Redefining Healthcare for a Healthier Tomorrow"
    //             highlightWords={['Healthier', 'Tomorrow']}
    //             highlightColor="#303A99"
    //             defaultColor="#000000"
    //             styleType="h42"
    //           />
    //         </Box>
    //         <Box>
    //           <TextColor
    //             text="Accessible, personalised care powered by technology to meet the challenges of today’s world."
    //             highlightWords={[]}
    //             highlightColor="#000000"
    //             defaultColor="#000000"
    //             styleType="body23"
    //           />
    //         </Box>

    //         <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ gap: 5 }}>
    //           <Box display="flex" flexDirection="row" sx={{ gap: 4,flexWrap:"wrap"}}>
    //             <Card2
    //               imageSrc={cad1}
    //               title="Rising Healthcare Costs"
    //               description="With medical expenses skyrocketing, Svasth offers affordable, tech-driven solutions that ensure quality care without the financial burden."
    //             />
    //             <Card2
    //               imageSrc={card2}
    //               title="Chronic Disease Management"
    //               description="60% of people over 45 live with chronic illness. Our tailored plans and real-time monitoring help manage these conditions effectively."
    //             />
    //             <Card2
    //               imageSrc={cad3}
    //               title="Aging Population"
    //               description="As people live longer, the need for comprehensive, continuous care is growing. Svasth’s integrated services cater to the needs of anyone."
    //             />
    //             <Card2
    //               imageSrc={cad4}
    //               title="Technology Integration"
    //               description="With healthcare increasingly relying on technology, Svasth is at the forefront, using real-time data and AI to revolutionize health management."
    //             />
    //           </Box>
    //         </Box>
    //         <Box display={"flex"} flexDirection={"column"} >
    //           <TextColor
    //             text=" Discover the solution that empowers you to confidently overcome challenges for a healthier future."
    //             highlightWords={[]}
    //             highlightColor="#000000"
    //             defaultColor="#000000"
    //             styleType="body11"
    //           />
    //         </Box>
    //         <Button text=" Contact Us" onClick={handleClick} />
    //       </Box>
    //     </Box>

    //       <Layout>
    //       <BlackCard
    //         imageLeft={false}
    //         image={accident}
    //         title="Quick Response to Medical Emergencies"
    //         subtitle="Lifesavr’s Swift Aid"
    //         description="In a serious car accident, a driver is left vulnerable. Yet, immediate care is delivered, thanks to the Lifesavr on the scene. Their quick, action using our solution ensures the driver receives the critical aid needed in those vital moments."
    //         buttonText="Know More"
    //       />
    //       <BlackCard
    //         imageLeft={true}
    //         background={"#ffffff"}
    //         textcolor={"#000000"}
    //         image={illness}
    //         buttonBackgroundColor={"#303A99"}
    //         textColor={"ffffff"}
    //         title="Emergency Response for Critical Illness"
    //         subtitle="Urgent Care Delivered"
    //         description="In a critical moment, a colleague’s quick intervention ensures the office worker with heart disease receives urgent care. Our solution empowers Lifesavrs with the tools for swift action in Emergencies."
    //         buttonText="Know More"
    //       />
    //       </Layout>

    //     <Box flexDirection={"column"} sx={{ alignItems: "center", width: "1920px", height: "905px" }}>
    //       <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ gap: 2 }}>
    //         <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
    //           <TextColor
    //             text=" AI Meets Health Management"
    //             highlightWords={['Management']}
    //             highlightColor="#303A99"
    //             defaultColor="#000000"
    //             styleType="h42"
    //           />
    //         </Box>
    //         <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ gap: 2 }}>
    //           <Box>
    //             <img
    //               src={img3}
    //               alt="Description "
    //               style={{
    //                 width: "300px", paddingLeft: "30px", position: "absolute",
    //                 left: "0.08%",
    //                 right: "0.03%",
    //               }}
    //             />
    //             <TextColor
    //               text="Combining cutting-edge technology with health and wellness, Svasth offers peace of mind and safety in every health condition."
    //               highlightWords={[]}
    //               highlightColor="#000000"
    //               defaultColor="#000000"
    //               styleType="body23"
    //             />
    //           </Box>

    //           <Box>
    //             <TextColor
    //               text="Embrace a safer, more balanced future with our health management protocol system. "
    //               highlightWords={[]}
    //               highlightColor="#000000"
    //               defaultColor="#000000"
    //               styleType="body23"
    //             />
    //           </Box>
    //         </Box>
    //       </Box>
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         justifyContent="space-between"
    //         gap="60px"
    //         sx={{
    //           flexWrap: 'wrap',
    //           paddingLeft: "2.08%",
    //           paddingRight: "2.08%",
    //           justifycontent: 'center',
    //           alignitems: 'center',
    //           isolation: 'isolate'
    //         }}
    //       >
    //         <Card3
    //           title="AI-Driven Healthcare Support"
    //           description="Get swift, accurate help when it matters most, easing your worries and enhancing vital, critical care."
    //           imageSrc={sqcd1}
    //         />
    //         <Card3
    //           title="Effortless Real-time Coordination"
    //           description="Navigate Emergencies with ease as we smooth out delays and chaos in health management processes."
    //           imageSrc={sqcd2}
    //         />
    //         <Card3
    //           title="Instant Alerts & Location"
    //           description="Stay connected and save time with instant notifications and real-time location sharing, simplifying processes"
    //           imageSrc={sqcd3}
    //         />
    //         <Card3
    //           title="Up-to-Date Medical Info"
    //           description="Receive updated medical data for relevant stakeholders, ensuring you get the right care efficiently.."
    //           imageSrc={sqcd4}
    //         />
    //         <Card3
    //           title="Rapid Emergency Response"
    //           description="Experience quicker Emergency response with real-time updates and efficient coordination, cutting down your wait."
    //           imageSrc={sqcd5}
    //         />
    //         <Card3
    //           title="Complete Care & Financial Ease"
    //           description="Enjoy stress-free support and seamless management of medical costs, so you can focus on what matters."
    //           imageSrc={sqcd6}
    //         />
    //       </Box>
    //       <FirstPageCard
    //         title="Join us in shaping a healthier, more connected future."
    //         subtitle="At Svasth, we’re not just keeping up with the changing times we’re leading the way. "
    //       />
    //       <Box>
    //       </Box>
    //     </Box>
    //   </Box>
    // </>
  );
};

export default MyApp;
